<template>
  <div class="device-container base-wrap">
    <Jump :jumpList="jumpList" />
    <NavMenu :activeUrl="activeUrl" />
    <div class="page-wrap">
      <chil-menu />
      <div class="page-main">
        <div class="filter-container" style="margin: 10px">
          <el-button-group   v-auth="['admin_org_add', 'admin_org_edit', 'admin_org_delete']">
            <el-button type="primary" icon="plus" @click="handlerAdd"
                       v-auth="['admin_org_add']"
            >新增
            </el-button>
            <el-button type="primary" icon="edit" @click="handlerEdit"
                       v-auth="['admin_org_edit']"
            >编辑
            </el-button>
            <el-button type="primary" icon="delete" @click="handleDelete"
                       v-auth="['admin_org_delete']"
            >删除
            </el-button>
          </el-button-group>
        </div>
        <div style="display: flex">
          <div class="org-left">
            <el-tree
                    :data="treeData"
                    :props="defaultProps"
                    :filter-node-method="filterNode"
                    class="filter-tree"
                    node-key="id"
                    highlight-current
                    default-expand-all
                    @node-click="getNodeData"
            />
          </div>
          <div class="org-right">
            <el-form
                    ref="form"
                    :label-position="labelPosition"
                    :rules="rules"
                    :model="form"
                    label-width="80px"
            >
              <el-form-item label="父级节点" prop="parentId">
                <el-input
                        v-model="form.parentId"
                        disabled
                        placeholder="请输入父级节点"
                />
              </el-form-item>
              <el-form-item v-if="formEdit" label="节点编号" prop="deptId">
                <el-input
                        v-model="form.deptId"
                        :disabled="formEdit"
                        placeholder="节点编号"
                />
              </el-form-item>
              <el-form-item label="部门名称" prop="name">
                <el-input
                        v-model="form.name"
                        :disabled="formEdit"
                        placeholder="请输入名称"
                />
              </el-form-item>
              <el-form-item label="排序" prop="sort">
                <el-input
                        v-model="form.sort"
                        :disabled="formEdit"
                        type="number"
                        placeholder="请输入排序"
                />
              </el-form-item>
              <el-form-item v-if="formStatus == 'update'">
                <div style="display: flex">
                  <el-button type="primary" @click="update">更新 </el-button>
                  <el-button type="primary" @click="onCancel">取消</el-button>
                </div>
              </el-form-item>
              <el-form-item v-if="formStatus == 'create'">
                <div style="display: flex">
                  <el-button type="primary" @click="create">保存 </el-button>
                  <el-button type="primary" @click="onCancel">取消</el-button>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Jump from '@/components/jump'
import NavMenu from "../navMenu.vue";
import {
  getDeptDetail,
  fetchDeptTree,
  putDept,
  delDept,
  addDept,
} from "@/api/deviceManagement.js";
import ChilMenu from "../../setting/chilMenu.vue";
export default {
  name: "org",
  data() {
    return {
      activeUrl: "orgManagement",
      list: null,
      total: null,
      formEdit: true,
      formAdd: true,
      formStatus: "",
      typeOptions: ["0", "1"],
      methodOptions: ["GET", "POST", "PUT", "DELETE"],
      listQuery: {
        name: undefined,
      },
      treeData: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      rules: {
        parentId: [
          { required: true, message: "请输入父级节点", trigger: "blur" },
        ],
        deptId: [
          { required: true, message: "请输入节点编号", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入部门名称", trigger: "blur" },
          {
            min: 3,
            max: 32,
            message: "长度在 3 到 32 个字符",
            trigger: "blur",
          },
        ],
        sort: [{ required: true, message: "请输入排序值", trigger: "blur" }],
      },
      labelPosition: "right",
      form: {
        name: undefined,
        sort: undefined,
        parentId: undefined,
        deptId: undefined,
      },
      currentId: 0,
      jumpList: [
        { name: "首页", url: "/" },
        { name: "权限管理", url: "userManagement" },
        { name: "组织机构", url: "" },
      ]
    };
  },
  created() {
    this.getList();
  },
  components: {
    ChilMenu,
    NavMenu,
    Jump
  },
  methods: {
    getList() {
      fetchDeptTree().then((response) => {
        this.treeData = response.data;
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    getNodeData(data) {
      if (!this.formEdit) {
        this.formStatus = "update";
      }
      getDeptDetail(data.id).then((response) => {
        this.form = response.data;
      });
      this.currentId = data.id;
    },
    handlerEdit() {
      if (this.form.deptId) {
        this.formEdit = false;
        this.formStatus = "update";
      }
    },
    handlerAdd() {
      this.resetForm();
      this.formEdit = false;
      this.formStatus = "create";
    },
    handleDelete() {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      }).then(() => {
        delDept(this.currentId).then(() => {
          this.getList();
          this.resetForm();
          this.onCancel();
          this.$notify.success("删除成功");
        });
      });
    },
    update() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        putDept(this.form).then(() => {
          this.getList();
          this.onCancel();
          this.$notify.success("更新成功");
        });
      });
    },
    create() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        addDept(this.form).then(() => {
          this.getList();
          this.onCancel();
          this.$notify.success("创建成功");
        });
      });
    },
    onCancel() {
      this.formEdit = true;
      this.formStatus = "";
    },
    resetForm() {
      this.form = {
        parentId: this.currentId,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  .page-main {
    background: transparent;
    box-sizing: border-box;
    padding: 0 20px;
    flex: 1;
    width: 0;
  }
}
.org-left {
  width: 230px;
  .org-lt {
    height: 55px;
    display: flex;
    align-items: center;
    padding: 0 13px;
    background: #113275;
    justify-content: space-between;
    p {
      color: #496cb4;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
.org-right {
  margin: 100px 0px 0px 150px;
}
.el-tree {
  padding-top: 15px;
  height: calc(100vh - 300px);
  background: #0c265a !important;
  color: #afdcff !important;
}
.el-card {
  background-color: transparent !important;
  border: none;
  margin-left: 40px;
}
::v-deep .el-form-item__label {
  color: #afdcff !important;
}
button {
  height: 36px;
  background: linear-gradient(0deg, #1f79ff, #23b4ff);
  border-radius: 2px;
  line-height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
</style>
