<template>
  <el-menu
    :default-active="activeUrl"
    class="el-menu-demo"
    mode="horizontal"
    @select="tabClick"
  >
    <el-menu-item index="userManagement" v-show="adminuserlist == 1"
      >用户管理</el-menu-item
    >
    <el-menu-item index="roleManagement" v-show="adminrolelist == 1"
      >角色管理</el-menu-item
    >
    <el-menu-item index="orgManagement" v-show="adminorglist == 1"
      >组织机构</el-menu-item
    >
    <el-menu-item index="menuManagement" v-show="adminmenulist == 1"
      >菜单管理</el-menu-item
    >
    <el-menu-item index="dictManagement" v-show="admindictlist == 1"
      >字典管理</el-menu-item
    >
  </el-menu>
</template>

<script>
export default {
  props: {
    activeUrl: {
      type: String,
      default: "userManagement",
    },
  },
  data() {
    return {
      adminuserlist: null, //用户管理
      adminmenulist: null, //菜单管理
      adminrolelist: null, //角色管理
      adminorglist: null, //组织机构
      admindictlist: null, //字典管理
    };
  },
  mounted() {
    let permissions = this.$store.state.app.permissions;
    permissions.forEach((item) => {
      if (item == "admin-user-list") {
        this.adminuserlist = 1;
      }
      if (item == "admin-menu-list") {
        this.adminmenulist = 1;
      }
      if (item == "admin-role-list") {
        this.adminrolelist = 1;
      }
      if (item == "admin-org-list") {
        this.adminorglist = 1;
      }
      if (item == "admin-dict-list") {
        this.admindictlist = 1;
      }
    });
  },
  methods: {
    tabClick(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped>
.el-menu {
  background: rgba(12, 39, 92, 0.9);
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 3px solid #3b98ff;
  color: #a3d6ff;
  opacity: 1;
}
.el-menu--horizontal > .el-menu-item {
  color: #a3d6ff;
  opacity: 0.36;
  margin-left: 20px;
  height: 48px;
  line-height: 48px;
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: transparent;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #a3d6ff;
}
</style>
